<template>
   
    <router-view />
</template>

<script>
import APP_DATA from "@/env";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/database";

firebase.initializeApp(APP_DATA.db_config[APP_DATA.env]);

export default {
    methods: {
        isLogin() {
            return this.$route.name == "login";
        },
    },
};
</script>

<style></style>
