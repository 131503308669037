import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/override.css'
import './assets/css/style.css'

import 'popper.js';
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
window.$ = window.jQuery = require('jquery')
// import './base'

createApp(App).use(router).mount('#app')
